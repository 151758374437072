<template>
	<div style="text-align: center;padding: 0; margin: 0">
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/01.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/02.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/03.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/04.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/05.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/06.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/07.jpg" style="width: 100%"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/tianyue/08.jpg" style="width: 100%"/>
		</div>
	</div>
		
</template>

<script>
import { init } from '@/common/weixinSDK'
export default {
	name: 'index',
	components: {
	},
	data() {
		return {
			title:"天约酒",
			imgUrl: 'https://static.tannatong.com/sanlinghui/common/goods_tianyue.jpg'
		}
	},
	created() {
		init({
			APIs: ['hideOptionMenu', 
			'hideAllNonBaseMenuItem', 
			'showMenuItems', 
			'hideMenuItems', 
			'onMenuShareTimeline', 
			'onMenuShareAppMessage'],
			callback: {
				'title': '天约酒',
				'imgUrl': this.imgUrl,
				'description': '好酒天天约',
				'url': "http://www.sangeling.com/goods/tianyue",
				successMethod: ''
			}
		})
	},
	methods: {
		
	}
}
</script>

<style>
	body{
		margin: 0;
	}
.title{
	background-color: #fad182; 
	border-radius: 30px; 
	display: inline-block;
	padding: 5px 20px;
	font-weight: bold;
}
.title2{
	color: #fff;
	font-weight: bold;
	display: inline-block;
	background-color: #000000; 
	border-radius: 4px;
}
.title2 .left{
	display: inline-block;
	padding: 5px 10px; 
}
.title2 .right{
	display: inline-block; 
	background-color: #e6141d;
	padding: 5px 10px; 
	border-radius: 0 4px 4px 0;
}
img{margin: 0;padding: 0;vertical-align: bottom/top;}
</style>
